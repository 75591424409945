import React from "react"

const Video = React.forwardRef(({ mp4, webm, ...props }, ref) => (
  <video ref={ref} {...props}>
    {webm && <source src={webm} type="video/webm; codecs=vp9,vorbis" />}
    <source src={mp4} type="video/mp4" />
  </video>
))

export default Video
