import React from "react"
import styled, { keyframes } from "styled-components"

import Logo from "components/logo"
import Hamburger from "./Hamburger"
import { HeaderLink, HEADER_HEIGHT } from "./styles"

const NavigationRoot = styled.nav`
  height: 100%;
  width: 200px;
  padding-top: ${HEADER_HEIGHT}px;
  z-index: 10;
  position: fixed;
  height: 100%;
  right: 0;
  background-color: black;
  transition: transform 300ms ease-out;
  transform: translateX(${(props) => (props.open ? "0" : "100%")});

  ${(props) => props.theme.breakpoints.up("md")} {
    background-color: transparent;
    position: static;
    padding-top: initial;
    height: initial;
    width: initial;
    transform: initial;
    width: 60%;
    max-width: 1200px;
    margin: auto;
  }
`

const Ul = styled.ul`
  overflow-y: auto;
  padding: 20px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: initial;
    overflow-y: initial;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
`

const Li = styled.li`
  margin-bottom: 30px;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: initial;
  }
`

const ContactLi = styled(Li)`
  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`

const colorChange = keyframes`
  0% {
    color: white;
  }

  50% {
    color: green;
  }

  100% {
    color: white;
  }
`

const TeamLi = styled(Li)`
  animation: ${colorChange} 2s infinite;
`

const HamburgerContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(${(props) => (props.open ? "0" : "-100%")});
  transition: transform 300ms ease-out;

  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`

const LogoSpace = styled.div`
  display: none;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
    width: 230px;
  }
`

const LogoContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
`

const LogoStyled = styled(Logo)`
  width: 100%;
  height: auto;
`

const Navigation = () => {
  const [open, setOpen] = React.useState(false)

  const toggleOpen = () => {
    setOpen((value) => !value)
  }

  return (
    <NavigationRoot open={open}>
      <HamburgerContainer open={open}>
        <Hamburger active={open} onClick={toggleOpen} />
      </HamburgerContainer>
      <Ul>
        <Li>
          <HeaderLink to="/">HOME</HeaderLink>
        </Li>
        <Li>
          <HeaderLink to="/#about-us">ABOUT US</HeaderLink>
        </Li>
        <LogoSpace>
          <LogoContainer>
            <LogoStyled />
          </LogoContainer>
        </LogoSpace>
        <Li>
          <HeaderLink to="/#our-projects">OUR PROEJCTS</HeaderLink>
        </Li>
        <TeamLi>
          <HeaderLink to="/team">TEAM</HeaderLink>
        </TeamLi>
        <ContactLi>
          <HeaderLink to="/#contact">CONTACT</HeaderLink>
        </ContactLi>
      </Ul>
    </NavigationRoot>
  )
}

export default Navigation
