import React from "react"
import styled from "styled-components"

import Carousel from "../Carousel"

import img1 from "./images/01_LaurBanerKolejnosc_png_140x78_001.png"
import img2 from "./images/01_LaurBanerKolejnosc_png_140x78_002.png"
import img3 from "./images/01_LaurBanerKolejnosc_png_140x78_003.png"
import img4 from "./images/01_LaurBanerKolejnosc_png_140x78_004.png"
import img5 from "./images/01_LaurBanerKolejnosc_png_140x78_005.png"
import img6 from "./images/01_LaurBanerKolejnosc_png_140x78_006.png"
import img7 from "./images/01_LaurBanerKolejnosc_png_140x78_007.png"
import img8 from "./images/01_LaurBanerKolejnosc_png_140x78_008.png"
import img9 from "./images/01_LaurBanerKolejnosc_png_140x78_009.png"
import img10 from "./images/01_LaurBanerKolejnosc_png_140x78_010.png"
import img11 from "./images/01_LaurBanerKolejnosc_png_140x78_011.png"
import img12 from "./images/01_LaurBanerKolejnosc_png_140x78_012.png"
import img13 from "./images/01_LaurBanerKolejnosc_png_140x78_013.png"
import img14 from "./images/01_LaurBanerKolejnosc_png_140x78_014.png"
import img15 from "./images/01_LaurBanerKolejnosc_png_140x78_015.png"
import img16 from "./images/01_LaurBanerKolejnosc_png_140x78_016.png"
import img17 from "./images/01_LaurBanerKolejnosc_png_140x78_017.png"

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
]

const Slide = styled.div`
  height: 100%;

  img {
    height: 100%;
    width: auto;
  }
`

const CarouselStyled = styled(Carousel)`
  .slick-list {
    height: 100%;
  }

  .slick-track {
    height: 100%;
  }

  .slick-slide > div {
    height: 100%;
  }
`

const Laurels = (props) => (
  <CarouselStyled
    accessibility={false}
    infinite
    lazyLoad
    autoplaySpeed={1000}
    autoplay
    arrows={false}
    dots={false}
    appendDots={false}
    fade
    {...props}
  >
    {images.map((src, index) => (
      <Slide key={index}>
        <img src={src} />
      </Slide>
    ))}
  </CarouselStyled>
)

export default Laurels
