import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import SocialMedia, { SocialIcon } from "components/SocialMedia"

import closeIcon from "./icons/close.png"
import whistleGif from "./icons/whistle.gif"

const CONTACT_DIALOG_COLOR = "#97F700"
const CONTACT_DIALOG_COLOR_2 = "#519700"

const ContactModalRoot = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CloseButton = styled.div.attrs({
  role: "button",
})`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 22px;
  height: 22px;
  background: url(${closeIcon});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("md")} {
    right: 20px;
    top: 20px;
    width: 43px;
    height: 43px;
  }
`

const ContactModalContent = styled.div`
  position: relative;
  width: 96%;
  max-width: 300px;
  max-height: 96%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${CONTACT_DIALOG_COLOR};
  font-size: 1rem;
  background-color: black;
  border: 3px ${CONTACT_DIALOG_COLOR_2} solid;
  padding: 0.5rem;

  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 830px;
    font-size: 2.5rem;
  }
`

const EmailLink = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  word-break: break-word;
  text-align: center;
  font-family: "VCR OSD Mono", Arial, sans-serif;
`

const Animation = styled.img.attrs(() => ({
  src: whistleGif,
}))`
  width: 65px;
  height: 65px;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 183px;
    height: 183px;
  }
`

const SocialMediaStyled = styled(SocialMedia)`
  margin: 1rem 0 1rem 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 2rem 0 1rem 0;

    ${SocialIcon} {
      width: 3rem;
      height: 3rem;
      margin: 1.5rem;
    }
  }
`

const InfoParagraph = styled.a`
  font-size: ${10 / 16}rem;
  margin-bottom: 1rem;
  text-align: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 1rem 0 2rem 0;
  }
`

const ContactDialogContext = React.createContext({
  open: false,
  onOpen: () => {},
  onClose: () => {},
})

function ContactDialogContextProvider(props: object) {
  const [open, setOpen] = React.useState(false)

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <ContactDialogContext.Provider
      {...props}
      value={{
        open,
        onOpen,
        onClose,
      }}
    />
  )
}

export { ContactDialogContextProvider, ContactDialogContext }

function ContactDialog() {
  const { open, onClose } = React.useContext(ContactDialogContext)

  const data = useStaticQuery(graphql`
    query {
      allSite {
        nodes {
          siteMetadata {
            contactEmail
          }
        }
      }
    }
  `)

  if (!open) return null

  const email = data.allSite.nodes[0].siteMetadata.contactEmail

  const mailto = `mailto:${email}`
  return (
    <ContactModalRoot onClick={onClose}>
      <ContactModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose} />
        <Animation />
        <EmailLink href={mailto}>{email}</EmailLink>
        <SocialMediaStyled
          color={CONTACT_DIALOG_COLOR_2}
          size={1.8}
          margin={0.7}
        />
        <InfoParagraph href={mailto}>
          Hello! | Cześć! | Privet! &gt;&gt;&gt; contact us in ENG | PL | RUS
        </InfoParagraph>
      </ContactModalContent>
    </ContactModalRoot>
  )
}

export default ContactDialog
