import React from "react"
import "./hamburgers.scss"

const Hamburger = ({ active, className, ...props }) => (
  <button
    className={`${className} hamburger hamburger--spin ${
      active ? "is-active" : ""
    }`}
    type="button"
    id="Menu"
    {...props}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner"></span>
    </span>
  </button>
)

export default Hamburger
