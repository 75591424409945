import React from "react"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { useStaticQuery, graphql } from "gatsby"

import SocialMedia from "./SocialMedia"

const FooterContainer = styled.footer`
  width: 100%;
  color: white;
  background-color: black;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -10;
`

const FooterContent = styled.div`
  max-width: 1130px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Row = styled.div`
  padding: 1.4rem;
`

const Header = styled.p`
  font-weight: bold;
`

const Text = styled.p`
  color: #8c8c8c;
`

const Link = styled(Text).attrs(() => ({
  as: "a",
}))`
  text-decoration: none;
`

const Footer = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      allSite {
        nodes {
          siteMetadata {
            contactEmail
          }
        }
      }
    }
  `)
  const email = data.allSite.nodes[0].siteMetadata.contactEmail

  return (
    <FooterContainer ref={ref} {...props}>
      <FooterContent>
        <Row>
          <Header>WANT MEET WITH US?</Header>
          <Text>
            <Link href={`mailto:${email}`}>{email}</Link>
          </Text>
        </Row>
        <Row>
          <Header>STAY WITH US!</Header>
          <SocialMedia />
        </Row>
        <Row>
          <Header>WEBSITE INFO:</Header>
          <Text>All Right Reserved &copy; Dyslectic Games</Text>
          <Link
            as={OutboundLink}
            href="https://www.linkedin.com/in/rzepkadariusz/"
            target="_blank"
            rel="noopener"
          >
            Website powered by Dariusz Rzepka
          </Link>
        </Row>
      </FooterContent>
    </FooterContainer>
  )
})

export default Footer
