import React from "react"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { useStaticQuery, graphql } from "gatsby"

import FbIcon from "./icons/SVG_facebook.inline.svg"
import InstagramIcon from "./icons/SVG_instagram.inline.svg"
import SnapchatIcon from "./icons/SVG_snapchat.inline.svg"
import TwitterIcon from "./icons/SVG_twitter.inline.svg"
import YoutubeIcon from "./icons/SVG_youtube.inline.svg"

const SOCIAL_MEDIA_ICONS = {
  facebook: FbIcon,
  instagram: InstagramIcon,
  snapchat: SnapchatIcon,
  twitter: TwitterIcon,
  youtube: YoutubeIcon,
}

const Root = styled.div``

export const SocialIcon = styled.svg`
  width: ${(props) => props.size}rem;
  height: ${(props) => props.size}rem;
  fill: ${(props) => props.color};
  margin: ${(props) => props.margin}rem;
`

const SocialMedia = ({
  size = 1.6,
  margin = 0.5,
  color = "#ddd",
  ...props
}) => {
  const data = useStaticQuery(graphql`
    query {
      allSite {
        nodes {
          siteMetadata {
            socialMedia {
              url
              name
            }
          }
        }
      }
    }
  `)

  const socialMediaData = data.allSite.nodes[0].siteMetadata.socialMedia

  return (
    <Root {...props}>
      {socialMediaData.map(({ url, name }) => (
        <OutboundLink key={name} href={url} target="_blank" rel="noopener">
          <SocialIcon
            color={color}
            size={size}
            margin={margin}
            as={SOCIAL_MEDIA_ICONS[name]}
          />
        </OutboundLink>
      ))}
    </Root>
  )
}

export default SocialMedia
