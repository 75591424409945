import { useNetworkStatus } from "react-adaptive-hooks/network"
import { useSaveData } from "react-adaptive-hooks/save-data"

export const useLightVersion = () => {
  // See https://github.com/GoogleChromeLabs/react-adaptive-hooks/issues/18
  if (typeof window === "undefined") return true

  const { effectiveConnectionType } = useNetworkStatus()
  const { saveData } = useSaveData()

  if (saveData) return true

  switch (effectiveConnectionType) {
    case "slow-2g":
    case "2g":
    case "3g":
      return true
    default:
      return false
  }
}
