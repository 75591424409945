import React from "react"
import styled from "styled-components"

import SocialMedia from "components/SocialMedia"
import ContactDialog, { ContactDialogContext } from "components/ContactDialog"

import Laurels from "../Laurels"
import Navigation from "./Navigation"
import { HeaderLink, HEADER_HEIGHT } from "./styles"

const Root = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  z-index: 10;
  background-color: black;
  display: flex;
  color: white;

  ${(props) => props.theme.breakpoints.up("md")} {
    background-color: transparent;
  }
`

const ContactLink = styled(HeaderLink).attrs(() => ({
  as: "div",
}))`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("md")} {
    right: 20px;
    left: initial;
  }
`

const LaurelsWrapper = styled.div`
  padding: 5px;
  height: 100%;
  width: 150px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0 15px;
    width: 170px;
    position: absolute;
    left: 0;
    bottom: -15px;
  }
`

const LaurelsText = styled.div`
  text-align: center;
  display: none;

  ${(props) => props.theme.breakpoints.up("md")} {
    display: inline-block;
    padding-bottom: 2px;
  }
`

const LaurelsStyled = styled(Laurels)`
  height: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: initial;
  }
`

const SocialMediaStyled = styled(SocialMedia)`
  display: none;

  ${(props) => props.theme.breakpoints.up("md")} {
    position: absolute;
    top: 100%;
    right: 65px;
    display: flex;
    flex-direction: column;
  }
`

const Header = () => {
  const { onOpen } = React.useContext(ContactDialogContext)

  return (
    <Root>
      <LaurelsWrapper>
        <LaurelsText>OUR PROJECTS displayed on:</LaurelsText>
        <LaurelsStyled />
      </LaurelsWrapper>
      <Navigation />
      <ContactLink onClick={onOpen}>CONTACT</ContactLink>
      <SocialMediaStyled />
      <ContactDialog />
    </Root>
  )
}

export default Header
