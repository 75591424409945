import React from "react"
import styled from "styled-components"
import { useDebouncedCallback } from "use-debounce"
import useEventListener from "@use-it/event-listener"

import { ContactDialogContextProvider } from "components/ContactDialog"

import GlobalStyles from "./global-styles"
import Footer from "./footer"
import Navigation from "./Header"

const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  position: relative;

  --after-height: 150px;

  &::after {
    content: "";
    width: 100%;
    height: var(--after-height);
    position: absolute;
    left: 0;
    pointer-events: none;
  }
`

const Layout = ({ children, ...props }: LayoutProps) => {
  const footerRef = React.useRef<HTMLElement>()
  const contentRef = React.useRef<HTMLDivElement>();

  const {
    callback: debouncedSetFooterHeight
  } = useDebouncedCallback(
    () => {
      if (contentRef.current && footerRef.current) {
        contentRef.current.style.setProperty('--after-height', `${
          Math.floor(footerRef.current.getBoundingClientRect().height) - 1
        }px`)
      }
    },
    // delay in ms
    16
  )

  useEventListener("resize", debouncedSetFooterHeight)

  React.useLayoutEffect(debouncedSetFooterHeight, [])

  return (
    <ContactDialogContextProvider>
      <GlobalStyles />
      <Navigation />
      <Content ref={contentRef} {...props}>{children}</Content>
      <Footer ref={footerRef} />
    </ContactDialogContextProvider>
  )
}

interface LayoutProps {
  children: React.ReactNode
}

export default Layout
